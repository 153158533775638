import React, { Component } from 'react';
import { Route,Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './components/header/index.jsx';
import Footer from './components/footer';
import App from './App';

import Home from './pages/home/index.jsx';
import Profile from './pages/profile/index.jsx';
import Project from './pages/project/index.jsx';
import ProjectDetail from './pages/project/detail.jsx';
import Skill from './pages/skill/index.jsx';
import Blog from './pages/blog/index.jsx';
import Book from './pages/book/index.jsx';
import Source from './pages/source/index.jsx';

const { Content } = Layout;
class IRouter extends Component {
    render(){
        return (
            <Layout>
                <Header></Header>
                <Content className="app-Content">     
                    <App> 
                        <Switch>
                            <Route exact path="/" component={Home}></Route>
                            <Route exact path="/profile" component={Profile}></Route>
                            <Route exact path="/project" component={Project}></Route>
                            <Route exact path="/projectDetail/:id" component={ProjectDetail}></Route>
                            <Route exact path="/skill" component={Skill}></Route>
                            <Route exact path="/book" component={Book}></Route>
                            <Route exact path="/blog" component={Blog}></Route>
                            <Route exact path="/source" component={Source}></Route>
                        </Switch>
                    </App>
                </Content>
                <Footer></Footer>
            </Layout>
        )
    };
}

export default IRouter;