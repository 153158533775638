import React, { Component } from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;

class FooterComponent extends Component {
    constructor(props) {
        super(props);
        this.linkOpen.bind(this)
    }
    linkOpen = () => {
        window.open('http://beian.miit.gov.cn/');
    };
    render(){
        return <Footer style={{ textAlign: 'center', color: '#909090', fontSize:'12px' }}>
            © Copyright By www.marongrong.com Start From 2002 All Rights Reserved. <br/>
            <div onClick={this.linkOpen} style={{cursor:'pointer'}}>滇ICP备16005387号-1</div>
            </Footer>;
    }
}
export default FooterComponent;