import React, { Component } from 'react';
import { Tabs, Pagination, Row, Col, Divider, Spin, BackTop } from 'antd';
import './index.css';
import { getProjectPage, getProjectTypePage } from '../../api';
import { qiniuPrefix } from '../../utils/constant';

const { TabPane } = Tabs;

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            type: 0,
            pageNumber:1,
            pageSize:8,
            typeData:[],
            listData:{}
        };
    }
    componentDidMount() {
        this.getTypeData();
    }
    // 获取项目分类 
    getTypeData = () =>{
        this.setState({loading:true});
        getProjectTypePage().then(res=>{
            if(res.success){
                const data = res.data || [];
                data.unshift({id:'0',title:'全部'})
                this.setState({
                    typeData : res.data || [],
                    type : res.data[0].id
                })
                this.getData();
            }
            this.setState({loading:false});
        })
    }
    // 获取项目列表
    getData = () =>{
        this.setState({loading:true});
        const params = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            type:this.state.type,
        }
        getProjectPage(params).then(res=>{
            if(res.success){
                this.setState({
                    listData : res.data || []
                })
            }
            this.setState({loading:false});
        })
    }
    // 监听页码变化
    handlePageChange = (pageNumber, pageSize) => {
        this.setState({
            pageNumber : pageNumber,
            pageSize : pageSize,
        },()=>{
            this.getData();
        });
    }
    // 监听Tab点击
    handleTabClick = (type) => {
        this.setState({
            type : type,
            pageNumber : 1,
        },()=>{
            this.getData();
        });
    }
    // 项目点击
    handleItemOnClick = (id) => {
        window.open(window.location.origin+'/#/projectDetail/'+id)
        // this.props.history.push('/projectDetail/' + id);
    }
    render(){
        return(
            <Spin size="large" spinning={this.state.loading}>
            <div className="content-wrap">
                <Divider>Total Projects {this.state.listData.allTotal}</Divider>
                <Tabs defaultActiveKey={this.state.typeData[0] && this.state.typeData[0].id} centered onTabClick={this.handleTabClick.bind(this)}>
                    {this.state.typeData.map((tItem, i) => ( 
                    <TabPane tab={tItem.title} key={tItem.id}>

                        <Row gutter={20} className="pro-list">
                            {this.state.listData.rows && this.state.listData.rows.map((item, j) => ( 
                                <Col span={6} key={j}>
                                    <div className="list-item" onClick={this.handleItemOnClick.bind(this, item.id)}>
                                        <div className="list-item-img">
                                            <img src={qiniuPrefix + item.showimg} alt=""/>
                                        </div>
                                        <div className="list-item-title">{item.title}</div>
                                        <div className="list-item-date">{item.workdate}</div>
                                    </div>
                                </Col>
                            ))}                            
                        </Row>

                        <Pagination 
                            defaultPageSize={this.state.pageSize}
                            defaultCurrent={1}
                            current={this.state.pageNumber}
                            onChange={this.handlePageChange.bind(this)}
                            showTotal={(total) => `Total: ${total} `}
                            showSizeChanger={false}
                            total={this.state.listData.total}/>
                    </TabPane>
                    ))}
                </Tabs>
            </div>
            <BackTop />
            </Spin>
        )
    }
}
export default Project;