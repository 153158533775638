import request from '../utils/request'

export function getProjectPage(params){
    return request({
      url: '/marongserver/project/list',
      method:'get',
      data:params
    })
}
export function getProjectTypePage(){
    return request({
      url: '/marongserver/project/type/list',
      method:'get'
    })
}
export function getProjecDetail(id){
    return request({
      url: '/marongserver/project/detail?id='+id,
      method:'get'
    })
}
export function getBookPage(){
    return request({
      url: '/marongserver/book/list',
      method:'get'
    })
}

