import React, { Component } from 'react';
import { Card, Spin, Row, Col } from 'antd';
import { getBookPage } from '../../api'
import { qiniuPrefix } from '../../utils/constant';
const { Meta } = Card;
class Book extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            data: []
        };
        this.getData.bind(this)
    }
    componentDidMount() {
        this.getData();
    }
    // 获取项目详情
    getData = () =>{
        this.setState({loading:true});
        getBookPage().then(res=>{
            if(res.success){
                this.setState({
                    data : res.data || {}
                })
            }
            this.setState({loading:false});
        })
    }
    render(){
        return(            
            <Spin size="large" spinning={this.state.loading}>
            <div className="content-wrap">
                <Row>
                {this.state.data.map((Item, i) => ( 
                    <Col span={4} key={i}>
                        <a href={Item.link} target="_blank">
                        <Card
                            hoverable
                            style={{ width: 180, marginBottom:20,height:300,padding:10 }}
                            cover={<img alt="example" height="200px" src={qiniuPrefix + Item.showimg} />}
                        >
                            <div>{Item.title}</div>
                        </Card>
                        </a>
                    </Col>
                ))}
                </Row>
            </div>
            </Spin>
        )
    }
}
export default Book;