
import React, { Component } from 'react';
import { Spin } from 'antd';

class Blog extends Component {
    constructor(props:any) {
        super(props);
        this.state = {
            loading:false
        };
    }
    componentWillMount() {
        this.setState({loading:true});
    }
    componentDidMount() {
        setTimeout(()=>{
            this.setState({loading:false});
        },1000)
    }
    render(){
        return(      
            <Spin size="large" spinning={this.state.loading}>      
            <div>
                <iframe src="http://www.marongrong.com:9000/" frameBorder="0" width="100%" style={{height:'calc(100vh - 170px)'}}/>
            </div>
            </Spin>
        )
    }
}
export default Blog;