import React, { Component } from 'react';

class Skill extends Component {
    render(){
        return(
            <div>
                <img width="100%" src="http://qiniu.marongrong.com/marongweb/upload/skill2020.png"/>
            </div>
        )
    }
}
export default Skill;