import React, { Component } from 'react';
import { PageHeader, Descriptions, Spin, BackTop } from 'antd';
import { getProjecDetail } from '../../api'
import './index.css';

class ProjectDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            data: {}
        };
        this.getData.bind(this)
    }
    componentDidMount() {
        this.getData();
    }
    // 获取项目详情
    getData = () =>{
        this.setState({loading:true});
        const id = this.props.match.params.id
        getProjecDetail(id).then(res=>{
            if(res.success){
                this.setState({
                    data : res.data || {}
                })
            }
            this.setState({loading:false});
        })
    }
    render(){
        return(
        <Spin size="large" spinning={this.state.loading}>
            <div className="site-page-header-ghost-wrapper">
            <PageHeader
                ghost={false}
                // onBack={() => window.history.back()}
                title={this.state.data.title}
                subTitle={this.state.data.typeName}
                extra={[
                    // <Button key="3">跳转官方</Button>,
                ]}
                >
                <Descriptions size="small" column={3}>
                    <Descriptions.Item label="项目时间"><strong>{this.state.data.workdate}</strong></Descriptions.Item>
                    <Descriptions.Item label="项目工期"><strong>{this.state.data.worktime}天</strong></Descriptions.Item>
                    <Descriptions.Item label="服务客户"><strong>{this.state.data.client}</strong></Descriptions.Item>
                    <Descriptions.Item label="项目描述" style={{textAlign:'left'}}><strong>{this.state.data.description || '暂无描述'}</strong></Descriptions.Item>
                </Descriptions>
                <div className="pro-detail-content" dangerouslySetInnerHTML={{__html: this.state.data.content}} style={{marginTop: '20px'}}></div>
            </PageHeader>
            </div>
            <BackTop />
        </Spin>
        )
    }
}
export default ProjectDetail;
