import React, { Component } from 'react';
import { Row, Col, Tooltip,Divider } from 'antd';
import './index.css';
import iHome_01 from '../../assets/i-home01.jpg';
import iHome_02 from '../../assets/i-home02.jpg';
import iHome_03 from '../../assets/i-home03.jpg';
import i_avatar from '../../assets/avatar.jpg';
import {
    TwitterOutlined,
    GithubOutlined,
    QqOutlined,
    WechatOutlined,
    DribbbleOutlined,
    MailFilled,
} from '@ant-design/icons';

class Contact extends Component {
    render(){
        return(            
            <div>
                <div className="contact-bg">
                    <div className="img"></div>
                </div>
                <div className="contact-bd content-wrap">
                    <div className="st-box">
                        <div className="st-image">
                            <img src={i_avatar} alt=""/>
                        </div>
                        <div className="st-title">Marong</div>
                        <div className="st-subtitle">Front-end Developer &amp; UX/UI Designer</div>
                        <div className="st-soc">
                            <Tooltip placement="bottom" title='marong87@sina.com'>
                            <a className="btn_animated">
                                <span className="circle"><span className="ink animate"></span><MailFilled /></span>
                            </a>
                            </Tooltip>
                            <Tooltip placement="bottom" title='marong6323'>
                            <a className="btn_animated">
                                <span className="circle"><span className="ink animate"></span><WechatOutlined /></span>
                            </a>
                            </Tooltip>
                            <Tooltip placement="bottom" title='849285229'>
                            <a className="btn_animated">
                                <span className="circle"><span className="ink animate"></span><QqOutlined /></span>
                            </a>
                            </Tooltip>
                            <a target="blank" href="https://github.com/mrr3000" className="btn_animated">
                                <span className="circle"><span className="ink animate"></span><GithubOutlined /></span>
                            </a>
                            <a target="blank" href="https://www.twitter.com/mrr3000" className="btn_animated">
                                <span className="circle"><span className="ink animate"></span><TwitterOutlined/></span>
                            </a>
                            <a target="blank" href="https://dribbble.com/marong" className="btn_animated">
                                <span className="circle"><span className="ink animate"></span><DribbbleOutlined /></span>
                            </a>
                        </div>
                     </div>
                </div>
                <div  className="content-wrap" style={{minHeight:'auto'}}>
                    <Divider style={{marginBottom:30,color:'#808080'}}>欢迎您的光临！言论、作品无论是好是坏， 望请指教。</Divider>
                    {/* <Row gutter={5}>                            
                        <Col span={8}>                        
                            <img src={iHome_01} alt="" style={{ width: '330px', height: '510px' }}/>
                        </Col>                       
                        <Col span={8}>                        
                            <img src={iHome_02} alt="" style={{ width: '330px', height: '510px' }}/>
                        </Col>                       
                        <Col span={8}>                        
                            <img src={iHome_03} alt="" style={{ width: '330px', height: '510px' }}/>
                        </Col>
                    </Row> */}
                </div>
            </div>
        )
    }
}
export default Contact;