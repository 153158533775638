import React, { Component } from 'react';
import { Row, Col, Anchor, BackTop } from 'antd';
import './index.css'
import img_edu from '../../assets/i-edu.jpeg'
import img_com_01 from '../../assets/i-company01.jpeg'
import img_com_02 from '../../assets/i-company02.jpeg'
import img_com_03 from '../../assets/i-company03.png'
import img_com_04 from '../../assets/i-company04.jpeg'
import img_cert_01 from '../../assets/i-cert01.png'

const { Link } = Anchor;
class Profile extends Component{

    handleAnchorClick = (e,link) => {
        e.preventDefault();
    };
    render(){
        return(
            <div className="content-wrap">
                <Row gutter={20}>
                    <Col span={20}>
                        {/* <div className="profile-box" id="profile01">
                            <div className="profile-box-hd"><h4>求职意向</h4></div>
                            <div className="profile-box-bd">
                                <div className="profile-item">
                                    前端开发 基础 node vue react 前端团队 培训 基建建设 性能优化 ssr等  熟悉服务端
                                </div>
                            </div>
                        </div> */}
                        <div className="profile-box" id="profile02">
                            <div className="profile-box-hd"><h4>自我评价</h4></div>
                            <div className="profile-box-bd">
                                <div className="profile-item">
                                    <p>本人毕业于云南财经大学，从事前端领域设计、开发、架构工作十多年。精通Vue、React、JavaScript、TypeScript，对前端架构、前端工程化、跨端开发、小程序、性能优化、部署运维及Node.js等整个前端技术栈有丰富的实战经验。长期致力于公司平台级框架开发、前端工程化、中后台系统、微前端架构、微服务技术转型、低代码平台开发和数据可视化开发。</p>
                                    <p>1、前端技术：熟练运用JavaScript、HTML、CSS、Node.js、ES6、TypeScript等技术，坚持高质量的设计理念和编码水准。</p>
                                    <p>2、基础框架：掌握JavaScript主流库，熟练运用JAVA MVC WEB开发（如JSP），精通jQuery、Bootstrap、Semantic UI、LayUI、EasyUI等。</p>
                                    <p>3、Vue框架：熟练掌握Vue 2.0/3.0开发单页应用（SPA），熟悉Vue SSR（Nuxt.js）开发，擅长使用ElementUI、ElementPlus、AntDesignVue、iView等框架。</p>
                                    <p>4、React框架：精通React 16/17开发单页应用（SPA），熟悉React SSR（Next.js）开发，擅长使用AntDesign、Umi、Redux（或React-Redux、Dva）等框架。</p>
                                    <p>5、服务端语言：掌握NodeJs、Java、PHP等服务端语言，并应用于实践项目中，持续学习全栈技术。</p>
                                    <p>6、跨端开发：熟练掌握UniApp、微信原生、React Native、变色龙、Vant、Taro.js等跨端开发框架，涉及小程序、企业微信、微信公众号、APP（iOS）、Web端和客户端（Electron）等。</p>
                                    <p>7、前端性能优化：熟悉前端性能优化技术，了解浏览器实现原理，熟悉交互设计理论，能够将优秀的交互设计落地到前端产品中。</p>
                                    <p>8、部署运维：在前端部署与运维方面，熟悉服务器操作，包括Linux服务器管理、Nginx配置、负载均衡、系统安全和性能监控等。熟练使用CI/CD工具（如Jenkins、GitLab CI）、自动化部署脚本、Docker容器化技术和Kubernetes集群管理，确保前端应用的高效交付与稳定运行。能够通过持续集成和持续交付优化开发流程，提高发布效率，并对线上系统进行实时监控和故障排查，保障系统的高可用性和性能。</p>
                                    <p>本人具备良好的服务意识和责任心，具有积极的团队沟通协作能力，善于发现并解决问题。对技术始终充满好奇心，乐于学习和分享，热爱前端技术栈，坚持阅读、写技术博客、前端学习笔记和开发优化方案，参与社区活动，运营微信学习群，分享学习资源。近期研究WebAssembly、Rust、WebGL、Three.js等技术，结合业务场景进行三维可视化展示和原生重构提升应用性能。</p>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box" id="profile03">
                            <div className="profile-box-hd"><h4>工作经历</h4></div>
                            <div className="profile-box-bd">
                                    
                                    <div className="profile-item clearfix">
                                        {/* <img src={img_com_02} alt=""/> */}
                                        <div className="right">
                                            <h4>[全职] XXXX科技有限公司 - 计算机软件 | 1000-3000人 | 国有企业<span>2014.06 - 至今</span></h4>
                                            <div>岗位: 高级前端工程师</div>
                                        </div>
                                        <div className="duty">                                            
                                            <p>1、工作项目简述，由于项目涉密未进行公开。主要包括门户、OA等协同类企业级办公系统；工作票、计划管理等生产类系统；协同办公APP等...</p>
                                            <p>2、主导公司前端基础设施建设的工作，其中包括前端脚手架工具CLI研发、前端VUE\REACT\NODEJS\UNIAPP等平台开发框架、前端组件库、NPM仓库、图标字体库、前端可视化开发框架研发（基于Echart+vue前端可视化平台+NODEJS服务端）、业务模板库（登录注册、列表CRUD、通讯录等）、前端开发规范制定（JS\CSS\VUE\REACT\组件开发规范）、设计资源平台（图标库、项目模板PSD库、原型RP套件、设计规范手册等）</p>
                                        </div>
                                    </div>

                                    <div className="profile-item clearfix">
                                        <img src={img_com_01} alt=""/>
                                        <div className="right">
                                            <h4>[全职] 昆明珍茗食品有限责任公司 - 快速消费品 | 500-1000人 | 民营公司<span>2011.02 - 2014.06</span></h4>
                                            <div>部门: IT项目部  岗位: 前端工程师 UI设计 </div>
                                        </div>
                                        <div className="duty">
                                            “珍茗金龙水”是云南省第一个桶装水品牌。昆明珍茗食品有限责任公司是云南方舟集团全资下属企业，创建于1995年，是云南省第一家开发、生产、销售桶装水的大型专业水企。我主要负责珍茗公司以及方舟集团旗下子公司各个门户网站的设计制作与维护，以及公司促销活动所涉及的平面设计相关工作。在公司工作三年的时间里，学习到了食品、机械、酒店、房产等领域的相关行业的专业设计知识、及企业级程序应用前端开发的知识，提升了自身技术能力。
                                        </div>
                                    </div>

                                    {/* <div className="profile-item clearfix">
                                        <img src={img_com_04} alt=""/>
                                        <div className="right">
                                            <h4>[兼职] 新西兰anytrade （罗顿艾伦斯网络科技有限公司） - 电商、批发零售 | 少于50人 | 民营公司<span>2009.10 - 2010.12</span></h4>
                                            <div>部门: 研发部  岗位: 网页设计师/美工 </div>
                                        </div>
                                        <div className="duty">
                                            Anytrade 是一家新西兰本地经营多种业务的公司，其中包括通讯资费、电子商务、线下门店购物、中介留学等行业的相关业务。兼职期间，主要负责电话卡面设计、商城网站界面设计、前端开发、广告海报制作及印刷等一系列的工作。在公司工作将近一年多的时间里，学习到了很多国外主流的设计风格，提升了自身实践能力。
                                        </div>
                                    </div> */}

                                    {/* <div className="profile-item clearfix">
                                        <img src={img_com_03} alt=""/>
                                        <div className="right">
                                            <h4>[兼职] 小熊IT007 - IT媒体平台 | 少于50人 | 民营公司<span>2004.07 - 2005.12</span></h4>
                                            <div>部门: 设计部  岗位: 网页设计师/美工 </div>
                                        </div>
                                        <div className="duty">                                            
                                            IT007（小熊在线）是一家21世纪新型的以网络为载体运营的信息门户公司，该公司涉及广告业，新闻业，IT业等多行业领域的业务。兼职期间，主要负责门户网站所需的FLASH广告制作，脚本编写，客户广告宣传页设计，静态页面制作，门户UI改版设计维护，信息发布，活动举办参与等工作。就职期间，主要参与了04/05年昆明IT行业商家广告在网络发布的工作，得到公司及客户的认可。并参与了网站维护信息发布及04年大学生电脑节的相关工作。
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        <div className="profile-box" id="profile04">
                            <div className="profile-box-hd"><h4>教育经历</h4></div>
                            <div className="profile-box-bd">
                                <div className="content">
                                    <div className="profile-item clearfix">
                                        <img src={img_edu} alt=""/>
                                        <div className="right">
                                            <h4>云南财经大学 <span>2006.09 - 2010.06</span></h4>
                                            <div>全日制本科 . 统招 / 传媒学院 广告学</div>
                                        </div>
                                        {/* <div className="duty">
                                            本专业培养具备宽广的文化与科学知识，掌握广告学、营销学、信息传播的理论与技能，能在各类媒介、广告公司、市场调查及信息咨询行业以及企事业单位的宣传、公关、广告、文化等部门从事广告经营管理、广告策划和创意、广告设计和制作、市场营销策划及市场调查分析工作的高级专门人才，同时具备互联网媒介相关广告、网页、程序等的UI/UX设计专业知识。
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box" id="profile06">
                            <div className="profile-box-hd"><h4>相关证书</h4></div>
                            <div className="profile-box-bd">

                                <div className="profile-item clearfix">
                                    <img src={img_cert_01} alt=""/>
                                    <div className="right">
                                        <h4>高级信息系统项目管理师 - 计算机技术与软件专业技术资格  <span>2020</span></h4>
                                        <div>发证机构: 中华人民共和国人力资源和社会保障部</div>
                                    </div>
                                </div>

                                <div className="profile-item clearfix">
                                    <img src={img_cert_01} alt=""/>
                                    <div className="right">
                                        <h4>高级系统架构设计师 - 计算机技术与软件专业技术资格  <span>2021</span></h4>
                                        <div>发证机构: 中华人民共和国人力资源和社会保障部</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </Col>
                    <Col span={4}>
                        <Anchor offsetTop={80} onClick={this.handleAnchorClick}>
                            {/* <Link href="#profile01" title="求职意向" /> */}
                            <Link href="#profile02" title="自我评价" />
                            <Link href="#profile03" title="工作经历" />
                            <Link href="#profile04" title="教育经历" />
                            {/* <Link href="#profile05" title="重点项目" /> */}
                            <Link href="#profile06" title="相关证书" />
                        </Anchor>
                    </Col>
                </Row>
                <BackTop />
            </div>
        )
    }
}
export default Profile;