import React, { Component } from 'react';
import { Layout, Menu  } from 'antd';
import { Link, HashRouter } from 'react-router-dom';

const { Header } = Layout;
class HeaderComponent extends Component {
  constructor(props) {
      super(props);
      this.state = {
        defaultKey: [''],
        menuData:{
          0:'#/',
          1:'#/profile',
          2:'#/project',
          3:'#/skill',
          4:'#/blog',
          5:'#/book',
          6:'#/source'
        }
      };
  }
  componentWillMount(props){
    const path = window.location.hash;
    let key = [];
    for(let k in this.state.menuData){
      if(this.state.menuData[k] === path) key.push(k)
    }
    this.setState({
      defaultKey: key
    })
  }
  render(){
      return (
        <HashRouter>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor:'#fff' }} className="app-Header">
          <div style={{width: '1300px', margin: '0 auto'}}>          
              <div className="logo" />
              <Menu theme="light" mode="horizontal" defaultSelectedKeys={this.state.defaultKey} style={{ marginTop: '-2px' }}>
              <Menu.Item key="0"><Link to="/">首页<span> / Home</span></Link></Menu.Item>
                <Menu.Item key="1"><Link to="/profile">个人简历<span> / Profile</span></Link></Menu.Item>
                <Menu.Item key="2"><Link to="/project">项目经历<span> / Project</span></Link></Menu.Item>
                <Menu.Item key="3"><Link to="/skill">技能图谱<span> / Skill</span></Link></Menu.Item>
                <Menu.Item key="4"><Link to="/blog">博客笔记<span> / Blog</span></Link></Menu.Item>
                <Menu.Item key="5"><Link to="/book">前端书单<span> / Book</span></Link></Menu.Item>
                <Menu.Item key="6"><Link to="/source">开源库<span> / Source</span></Link></Menu.Item>
              </Menu>
          </div>
        </Header>
        </HashRouter>
      );
  }
}
export default HeaderComponent;